import React, { FC } from 'react';
import classNames from 'classnames';

interface Props {
  label: string | JSX.Element;
  className?: string;
}

export const Section: FC<Props> = props => {
  return (
    <section className={classNames('pt-5', 'pb-5', 'text-white', 'w-full', 'md:w-2/6', 'md:mr-4', 'md:last:mr-0')}>
      <header className="flex justify-start items-center mb-3 pb-2 border-b border-brown-200 border-dashed">
        <i className="icon filled-circle-right text-xl mr-3" />
        <h3 className="text-2xl">{props.label}</h3>
      </header>
      <article>
        {props.children}
      </article>
    </section>
  );
};
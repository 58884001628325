import React, { FC } from 'react';
import { FeaturedImage as FeaturedImageType } from 'types';
import { GatsbyImage } from 'gatsby-plugin-image';
  
interface Props {
  featuredImage: FeaturedImageType;
}
  
export const FeatureImage: FC<Props> = props => {
  return props.featuredImage.data ? (
    <div className="image h-full overflow-hidden relative">
      <GatsbyImage
        className="slider-image top-0 left-0 w-full"
        image={props.featuredImage.data}
        alt={props.featuredImage.alt}
        style={{ height: 330 }}
      />
    </div>
  ) : null;
};
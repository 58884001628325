import React, { FC, useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { PageLocation, FeaturedImage, MenuItem } from 'types';
import { topMenuHeight, headerHeightXS, getIsMobile } from 'utils';
import { LanguageSwitch } from '../languageSwitch';
import { Logo } from '../logo';
import { TopMenu } from '../topMenu';
import { Wrapper } from '../wrapper';
import { HeaderSlider } from '../headerSlider';
import { FeatureImage } from '../featuredImage';
import './styles.css';
  
interface Props {
  location: PageLocation;
  showSlider?: boolean;
  featuredImage?: FeaturedImage;
  menuItems: MenuItem[];
  title?: string;
}
  
export const Header: FC<Props> = props => {
  const [isSticky, setIsSticky] = useState(false);
  const isMobile = getIsMobile();
  const isStaticHeader = !isSticky || !isMobile;  

  const handleScroll = useCallback(() => {
    const scrollY = window.scrollY;

    setIsSticky(state => {
      if (state) {
        return scrollY !== 0;
      } else {
        return scrollY >= headerHeightXS - topMenuHeight;
      }
    });
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const renderMedia = () => {
    if (props.showSlider) {
      return (
        <HeaderSlider />
      );
    }

    if (props.featuredImage) {
      return (
        <FeatureImage featuredImage={props.featuredImage} />
      );
    }

    return null;
  };

  return (
    <CSSTransition
      in={isStaticHeader}
      timeout={500}
      classNames="header"
    >
      <header className={classNames('relative shadow-header', {
        'h-300px md:h-330px': isStaticHeader,
        'sticky top-0 z-30': !isStaticHeader
      })}>
        {isStaticHeader && renderMedia()}
        <Wrapper className={classNames('absolute top-0 left-0 right-0', {
          'zero-padding': !isStaticHeader
        })}>
  
          <Logo className={classNames('transform origin-center', {
            visible: isStaticHeader,
            invisible: !isStaticHeader
          })} />
          <div className="relative w-full h-full">
            <div className={classNames('menu-wrapper w-full transform relative z-30', {
              'translate-y-220px': isStaticHeader,
              'translate-y-0': !isStaticHeader
            })}>
              <LanguageSwitch />
              <TopMenu 
                title={props.title || ''}
                location={props.location}
                menuItems={props.menuItems}
              />
            </div>
          </div>
        </Wrapper>
      </header>
    </CSSTransition>
  );
};
import React, { FC } from 'react';
import { Link } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import { StaticImage } from 'gatsby-plugin-image';
import classNames from 'classnames';

interface Props {
  className?: string;
}

export const Logo: FC<Props> = props => {
  const { className = '' } = props;
  return (
    <div className={classNames('logo absolute select-none top-0 m-auto left-0 right-0 w-180px z-10 text-center bg-white bg-opacity-80', className)}>
      <div className="logo-img flex justify-center">
        <Link to="/">
          <StaticImage
            src="../../../../content/assets/logo.png"
            placeholder="tracedSVG"
            layout="fixed"
            alt="logo"
            width={124}
            height={128}
            quality={90}
          />
        </Link>
      </div>
      <div className="logo-text text-center text-dark font-serif">
        <div className="text-4xl">
          <FormattedMessage
            id="page_title"
            defaultMessage="Almuka"
          />
        </div>
        <div className="text-sm pb-3">
          <FormattedMessage
            id="slogan"
            defaultMessage="Geras poilsis Palangoje"
          />
        </div>
      </div>
    </div>
  );
};

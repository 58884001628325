import { Language } from 'types';

export const languages = ['lt', 'en', 'ru'] as const;
export const defaultLanguage: Language = 'lt';
export const roomsCategoryIds = ['dGVybTo4OA==', 'dGVybTozMw==', 'dGVybTozOTk='];
export const roomsMenuIds = ['cG9zdDo2Nw==', 'cG9zdDoxMjQ=', 'cG9zdDo4MzU='];
export const maxRating = 5;
export const topMenuHeight = 50;
export const headerHeightXS = 300;
export const baseUrl = 'https://www.admin.almuka.lt/wp-json';

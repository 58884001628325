import React, { FC, useState, useEffect, useRef, useCallback } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import classNames from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FeaturedImage } from 'types';

import './slider.css';

interface Props {
  images: FeaturedImage[];
}

const getNewIndex = (oldIndex: number, imagesLength: number, direction: 'left' | 'right') => {
  const change = direction === 'left' ? -1 : 1;
  return (oldIndex + imagesLength + change) % imagesLength;
};

export const Slider: FC<Props> = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const intervalId = useRef<NodeJS.Timer | null>(null);
  const { images } = props;

  const clearIntervalId = useCallback(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
  }, [intervalId]);

  useEffect(() => {
    const id = setInterval(() => {
      setActiveIndex(oldIndex => getNewIndex(oldIndex, images.length, 'right'));
    }, 5000);

    intervalId.current = id;

    return () => {
      clearIntervalId();
    };
  }, [images, intervalId, clearIntervalId]);

  const prevIndex = getNewIndex(activeIndex, images.length, 'left');
  const nextIndex = getNewIndex(activeIndex, images.length, 'right');

  const getSharedArrowClass = () => `
    hidden
    sm:block
    arrow-icon
    absolute
    transform
    -translate-y-50%
    top-50%
    bg-white
    opacity-60
    hover:opacity-100
    cursor-pointer
    z-10
  `;

  const handleSlideChange = (newIndex: number) => {
    setActiveIndex(newIndex);
    clearIntervalId();
  };

  const renderThumbs = () => (
    <ul className="absolute ml-auto mr-auto bottom-1 text-center left-0 right-0 z-10">
      {props.images.map((image, index) => (
        <li
          onClick={() => handleSlideChange(index)}
          key={index}
          className={classNames(
            'w-10px',
            'h-10px',
            'ml-2px',
            'mr-2px',
            'inline-block',
            'rounded-full',
            'list-none',
            'cursor-pointer',
            'select-none',
            {
              'bg-yellow': activeIndex === index,
              'bg-brown': activeIndex !== index
            }
          )}
        />
      ))}
    </ul>
  );

  const renderImages = () => (
    <TransitionGroup>
      {images.map((image, index) => image.data && index === activeIndex && (
        <CSSTransition
          key={index}
          timeout={500}
          classNames="item"
        >
          <GatsbyImage
            key={index}
            className={classNames('slider-image', 'top-0', 'left-0',  'w-full', 'absolute')}
            image={image.data}
            alt={image.alt}
            style={{ height: 330 }}
          />
        </CSSTransition>
      ))}
    </TransitionGroup>
  );

  const renderArrows = () => (
    <>
      <div
        role="button"
        tabIndex={0}
        aria-label="Previous slide"
        onClick={() => handleSlideChange(prevIndex)}
        className={classNames('arrow-left', 'left-20px', 'rotate-180', getSharedArrowClass())}
      />
      <div
        role="button"
        tabIndex={0}
        aria-label="Next slide"
        onClick={() => handleSlideChange(nextIndex)}
        className={classNames('arrow-right', 'right-20px', getSharedArrowClass())}
      />
    </>
  );

  return (
    <div className="image h-full overflow-hidden relative">
      {renderImages()}
      {renderThumbs()}
      {renderArrows()}
    </div>
  );
};


import React from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { GalleryImage } from '@designus/react-simple-gallery';
import { TableColumn } from 'components/table';
import xss from 'xss';
import { MenuItem, Rooms, ApiPath, StaticTableData, Language } from '../types';
import { roomsCategoryIds, languages, baseUrl, defaultLanguage } from './constants';

export const getLocalizedPath = (locale: string, path?: string): string =>
  locale === defaultLanguage ? path || '' : `/${locale || ''}${path || ''}`;

export const isTruthy = <T,>(value: T | null | undefined): value is T => value != null;

export const sortAscending = (a: any, b: any) => a - b;

export const sortDescedning = (a: any, b: any) => b - a;

export const getRandomInteger = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const voidFn = (): void => undefined;

export const generateList = (num: number) => Array.from(Array(num).keys());

export const areEqual = <T,>(keysToCompare: (keyof T)[]) => (prevProps: T, nextProps: T) => 
  keysToCompare.every(key => prevProps[key] === nextProps[key]);

export const getRooms = (posts: Rooms, locale?: string): Rooms => {
  return posts.filter(node => {
    const hasCorrectLanguage = node?.language?.language === locale;
    const hasCorrectCategory = node.categories?.nodes?.some(category => category?.id && roomsCategoryIds.includes(category?.id));
    return hasCorrectCategory && hasCorrectLanguage;
  });
};

export const isActiveMenu = (item: MenuItem, pathName = '') => {

  const replacedPathName = pathName.replace(new RegExp(`/(${languages.join('|')})`), '');
  const isActive = item.path === replacedPathName;

  if (item.items.length === 0) {
    return isActive;
  }

  return isActive || item.items.some(childItem => childItem.path && pathName.includes(childItem.path)) ;
};

export const generateUrl = (path: ApiPath) => baseUrl + path;

export const getStaticTableColumns = (): TableColumn<StaticTableData>[] => [
  {
    id: 1,
    field: 'left',
    headerName: '',
    align: 'left'
  },
  {
    id: 2,
    field: 'right',
    headerName: '',
    align: 'left'
  }
];

export const isSSR = typeof window === 'undefined';

export const getIsMobile = () => !isSSR ? window.innerWidth <= 768 : false;

export const mapGraphqlMediaToGalleryImages = (locale: Language, images: GatsbyTypes.Maybe<readonly GatsbyTypes.Maybe<GatsbyTypes.GalleryFieldsFragment>[]>): GalleryImage<IGatsbyImageData>[] => {
  return (images || [])
    .filter(item => isTruthy(item?.localFile?.childImageSharp))
    .map(item => {
      const data = item?.localFile?.childImageSharp as Pick<GalleryImage<IGatsbyImageData>, 'full' | 'thumb'>;
      const { alt, title, caption } = item?.mediaAttribute || {};
      return {
        thumb: data?.thumb,
        full: data?.full,
        thumbAlt: alt?.[locale] || '',
        title: title?.[locale] || '',
        caption: caption?.[locale] || ''
      };
    });
};


export const setInnerHtml = <T extends HTMLElement>(html: string): React.DOMAttributes<T>['dangerouslySetInnerHTML'] => ({
  __html: xss(html, {
    whiteList: {
      a: ['href', 'title', 'target'],
      br: [],
      p: []
    },
    stripIgnoreTag: true
  })
});

export const getLocaleFromPath = (path: string) => {
  const match = path.match(new RegExp(`^/(${languages.join('|')})/`));
  if (!match) return defaultLanguage;
  return match[1] as Language;
};
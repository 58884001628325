import React, { FC } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { FormattedMessage, useIntl } from 'react-intl';
import { setInnerHtml } from 'utils';
import { Section } from '../section';
import { Language } from 'types';

const lookup: Record<string, Language> = {
  'block-11': 'lt',
  'block-12': 'en',
  'block-13': 'ru'
};

export const Contacts: FC = () => {
  const intl = useIntl();
  const data = useStaticQuery<GatsbyTypes.ContactWidgetQuery>(graphql`
    query ContactWidget {
      allWpSidebar(filter: {name: {eq: "Contacts"}}) {
        nodes {
          widgets {
            nodes {
              html
              databaseId
            }
          }
          name
        }
      }
    }
  `);

  const localizedWidget = data.allWpSidebar.nodes
    .map(node => node.widgets?.nodes)
    .flat()
    .find(widget => widget?.databaseId && lookup[widget?.databaseId] === intl.locale);

  return localizedWidget?.html || '' ? (
    <Section label={<FormattedMessage id="contacts" defaultMessage="Kontaktai" />}>
      <div dangerouslySetInnerHTML={setInnerHtml(localizedWidget?.html || '')} />
    </Section>
  ) : null;
};
import React, { ReactNode, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { Seo } from 'components/seo';
import { FeaturedImage, GraphqlMenuItem, PageLocation, LayoutRenderProps } from 'types';
import { getRooms } from 'utils';
import { Wrapper } from './wrapper';
import { Footer } from './footer';
import { ContactUs } from './contactUs';
import { Header } from './header';
import { removeChildItems, mapGraphqlMenuItemsToMenuItem, getSortedReviews } from './utils';

import './layout.css';

interface Props {
  isHomePage?: boolean;
  featuredImage?: FeaturedImage;
  location: PageLocation;
  title?: string;
  seoTitle: string;
  metaDescription?: string;
  metaKeywords?: string;
  showSlider?: boolean;
  showTitle?: boolean;
  addSidePadding?: boolean;
  children?: ReactNode | ((renderProps: LayoutRenderProps) => ReactNode)
}

const Layout = ({
  isHomePage,
  location,
  title = '',
  seoTitle,
  metaDescription,
  showSlider,
  featuredImage,
  children,
  showTitle = true,
  addSidePadding = true
}: Props) => {
  const intl = useIntl();
  const data = useStaticQuery<GatsbyTypes.LayoutQuery>(graphql`
    query Layout {
      allWpPost(sort: { fields: roomAttributes___order, order: ASC }) {
        nodes {
          excerpt
          uri
          id
          title
          language {
            language
          }
          categories {
            nodes {
              id
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    height: 270,
                    quality: 70,
                    placeholder: BLURRED,
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
          roomAttributes {
            shortDescription
            prices {
              ...PricesFields
            }
          }
        }
      }
      allWpMenuItem(sort: { fields: order }) {
        edges {
          node {
            id
            path
            parentId
            label
            title
            order
            menu {
              node {
                language {
                  language
                }
                name
              }
            }
          }
        }
      }
      allWpReview {
        nodes {
          title
          content
          language {
            language
          }
          review {
            adminReply
            city
            date
            email
            name
            rating
            showEmail
          }
        }
      }
    }
  `);

  const grapqhlMenuItems: GraphqlMenuItem[] = data.allWpMenuItem.edges.filter(item => item.node.menu?.node?.language?.language === intl.locale);
  const menuItems = removeChildItems(mapGraphqlMenuItemsToMenuItem(grapqhlMenuItems));
  const rooms = getRooms(data.allWpPost.nodes, intl.locale);
  const reviews = getSortedReviews(data.allWpReview.nodes);

  const [isContactUsModalOpen, setIsContactUsModalOpen] = useState(false);

  const toggleContactUsModal = () => {
    setIsContactUsModalOpen(!isContactUsModalOpen);
  };

  const renderTitle = () => showTitle ? (
    <h1 className="mb-5 text-3xl">
      {title}
    </h1>
  ) : null;

  const renderChildren = () => typeof children === 'function'
    ? children({ menuItems, location, rooms, reviews })
    : children;

  return (
    <div className="wrapper" data-is-root-path={isHomePage}>
      <Header
        location={location}
        showSlider={showSlider}
        featuredImage={featuredImage}
        menuItems={menuItems}
        title={title}
      />
      <Wrapper className="pt-50px">
        <Seo
          title={seoTitle}
          description={metaDescription}
        />
        <main className={classNames({'lg:pl-25px lg:pr-25px': addSidePadding})}>
          {renderTitle()}
          {renderChildren()}
        </main>
      </Wrapper>
      <Footer
        menuItems={menuItems}
        reviews={reviews}
        openContactForm={toggleContactUsModal}
      />
      <ContactUs
        isModalOpen={isContactUsModalOpen}
        toggleModal={toggleContactUsModal}
      />
    </div>
  );
};

export default Layout;

/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface Props {
  description?: string;
  meta?: any[];
  title: string;
}

export const Seo = (props: Props) => {
  const { description = '', meta = [], title = '' } = props;
  const intl = useIntl();
  const { wp } = useStaticQuery<GatsbyTypes.SeoQuery>(
    graphql`
      query Seo {
        wp {
          generalSettings {
            title
            description
          }
        }
      }
    `
  );

  const metaDescription = description || wp?.generalSettings?.description;
  const defaultTitle = wp?.generalSettings?.title;

  return (
    <Helmet
      htmlAttributes={{
        lang: intl.locale,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : ''}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
      ].concat(meta)}
    />
  );
};

// Seo.defaultProps = {
//   lang: 'en',
//   meta: [],
//   description: '',
// };


// export default Seo;

import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'gatsby';
import { MenuItem } from 'types';
import { roomsMenuIds, getLocalizedPath } from 'utils';
import '__generated__/gatsby-types';
import { Section } from '../section';

interface Props {
  menuItems: MenuItem[];
}

export const RoomsMenu: FC<Props> = props => {
  const childMenuItems = props.menuItems.find(item => roomsMenuIds.includes(item.id))?.items || [];
  const intl = useIntl();
  return childMenuItems.length ? (
    <Section label={<FormattedMessage id="rooms" defaultMessage="Kambariai" />}>
      <ul className="rooms-menu">
        {childMenuItems.map(childMenuItem => (
          <li
            className="inline-block pr-1"
            key={childMenuItem.id}
          >
            <Link
              className={'hover:text-yellow'}
              to={getLocalizedPath(intl.locale, childMenuItem.path || '/')}
            >
              {childMenuItem.label}
            </Link>
          </li>
        ))}
      </ul>
    </Section>
  ) : null;
};
import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { languages, defaultLanguage } from 'utils';


export const LanguageSwitch: FC = () => {
  const intl = useIntl();
  return (
    <div className="languages absolute text-white z-30 -top-200px right-5 lg:top-0 lg:bottom-0 lg:flex lg:items-center">
      <ul className="m-0 p-0">
        {languages.map(language => (
          <li className="block xs:inline-block list-none pr-1 pb-1 italic text-sm capitalize xs:pb-0" key={language}>
            <Link
              to={language === defaultLanguage ? '/' : `/${language}/`}
              className={classNames(
                'w-7',
                'inline-block',
                'text-center',
                'no-underline',
                'pt-1',
                'pb-1',
                'rounded-tl-xl',
                'rounded-br-xl',
                'lg:border',
                'lg:border-solid',
                'hover:bg-yellow-400',
                'lg:hover:border-yellow-400',
                {
                  'bg-yellow-400': language === intl.locale,
                  'lg:border-yellow-400': language === intl.locale,
                  'bg-brown': language !== intl.locale,
                  'lg:border-brown-300': language !== intl.locale
                }
              )} 
            >
              {language}
            </Link>
          </li>
          )
        )}
      </ul>
    </div>
  );
};

import React, { FC, useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Link } from 'gatsby';
import debounce from 'lodash/debounce';
import { CSSTransition } from 'react-transition-group';
import { getLocalizedPath, useOnClickOutside, isActiveMenu, topMenuHeight, getIsMobile } from 'utils';
import { MenuItem } from 'types';
import { Props } from './types';
import '__generated__/gatsby-types';

import './styles.css';

export const TopMenu: FC<Props> = props => {
  const intl = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const [isParentDropdownVisible, setIsParentDropdownVisible] = useState(false);
  const [isChildDropdownVisible, setIsChildDropdownVisible] = useState<Record<string, boolean>>(props.menuItems.reduce<Record<string, boolean>>((acc, item) => {
    if (item.items.length > 0 && getIsMobile()) {
      acc[item.id] = isActiveMenu(item, props.location?.pathname);
    }
    return acc;
  }, {}));
  const [isMobile, setIsMobile] = useState(false);
  const { current: handleResize } = useRef(debounce(() => {
    setIsParentDropdownVisible(false);
    setIsMobile(getIsMobile());
  }, 300));

  useEffect(() => {
    setIsMobile(getIsMobile());
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const toggleParentDropdown = () => {
    setIsParentDropdownVisible(!isParentDropdownVisible);
  };

  const toggleChildDropdown = (event: React.MouseEvent<HTMLLIElement>, id: string) => {
    event.stopPropagation();
    setIsChildDropdownVisible(state => ({ ...state, [id]: !isChildDropdownVisible[id] }));
  };

  useOnClickOutside(ref, () => {
    setIsParentDropdownVisible(false);
    setIsChildDropdownVisible({});
  });

  const renderChildMenuItem = (item: MenuItem) => (
    <li className={classNames('border-b', 'md:last:border-b-0')} key={item.id}>
      <Link
        className={classNames(
          'pt-2',
          'pb-2',
          'pl-4',
          'pr-4',
          'text-15px',
          'text-dark',
          'block',
          'hover:bg-gray-200',
          {
            'bg-gray-200': isActiveMenu(item, props.location?.pathname)
          }
        )}
        to={getLocalizedPath(intl.locale, item?.path)}
      >
        {item.label || ''}
      </Link>
    </li>
  );

  const showChildDropdown = (item: MenuItem) => isChildDropdownVisible[item.id];

  const renderChildMenu = (item: MenuItem) => item.items?.length ? (
    <ul className={classNames(
      'bg-white',
      'not-italic',
      'md:absolute',
      'md:shadow-xl',
      'text-left',
      {
        'hidden': !showChildDropdown(item),
        'block': showChildDropdown(item)
      }
    )}>
      {item.items.map(renderChildMenuItem)}
    </ul>
  ) : null;

  const renderParentMenuItem = (item: MenuItem) => {
    const isChildOpen = showChildDropdown(item);
    const hasChildren = item.items.length > 0;
    return (
      <li 
        key={item.id}
        className={classNames(
          'md:inline-block',
          'md:border-r',
          'md:border-brown-300',
          'md:last:border-r-0', {
            parent: hasChildren
          }
        )}
        onClick={(event) => toggleChildDropdown(event, item.id)}
      >
        <Link
          to={getLocalizedPath(intl.locale, item?.path)}
          onClick={e => {
            if (item.path === '/' && hasChildren) {
              e.preventDefault();
            }
          }}
          className={classNames(
            'hover:bg-yellow-400',
            'border-b',
            'border-white',
            'border-opacity-10',
            'pt-3',
            'pb-3',
            'pl-4',
            'pr-4',
            'flex',
            'justify-between',
            'md:block',
            'leading-6',
            'md:pt-5px',
            'md:pb-5px',
            'md:text-brown-100',
            'md:border-b-0',
            {
              'bg-yellow-400': isChildDropdownVisible[item.id] || isActiveMenu(item, props.location?.pathname)
            }
          )}
        >
          {item.label || ''}
          <i className={classNames('expand-icon', 'icon', 'md:hidden', {
            plus: hasChildren && !isChildOpen,
            minus: hasChildren && isChildOpen
          })} />
        </Link>
        {renderChildMenu(item)}
      </li>
    );
  };

  const renderParentMenu = () => (
    <ul className={classNames(
      'bg-brown',
      'text-white',
      'border-t',
      'max-h-0',
      'border-white',
      'border-opacity-10',
      'text-17px',
      'italic',
      'overflow-hidden',
      'relative',
      'z-20',
      'md:max-h-screen',
      'md:text-center',
      'md:not-italic',
      'md:border-0',
      'md:w-full',
      'md:pt-5px',
      'md:pb-5px',
      'md:border-t',
      'md:border-b',
      'md:border-white',
      'md:border-dashed',
      'md:border-opacity-20',
      'md:overflow-visible'
    )}>
      {props.menuItems.map(renderParentMenuItem)}
    </ul>
  );

  const renderDesktopMenu = () => renderParentMenu();

  const renderMobileMenu = () => (
    <CSSTransition
      in={isParentDropdownVisible}
      classNames="menu-slide"
      timeout={300}
    >
      {renderParentMenu()}
    </CSSTransition>
  );

  const pathName = props.location?.pathname;
  const isHomePage = pathName === '/';

  const renderPageTitle = () => isHomePage && (
    <div className="text-18px">
      <FormattedMessage
        id="page_title"
        defaultMessage="Almka"
      />
    </div>
  );

  const renderBreadcrumbs = () => !isHomePage && (
    <div className="text-white text-16px">
      <Link to="/" aria-label="Home page">
        <i className="icon house" />
      </Link>
      <i className="icon chevron-right text-14px pl-5px pr-5px" />
      <span className="truncate">
        {props.title}
      </span>
    </div>
  );

  const renderMobileMenuBar = () => (
    <div className="mobile md:hidden flex items-center justify-between w-full pl-4 pr-4 pt-2 pb-2 h-full">
      {renderPageTitle()}
      {renderBreadcrumbs()}
      <button
        onClick={toggleParentDropdown}
        type="button"
        aria-label="Toggle menu"
        className={classNames(
          'burger',
          'hover:bg-yellow',
          'border',
          'flex',
          'flex-col',
          'justify-between',
          'border-white-50',
          'w-10',
          'border-opacity-40',
          'h-full',
          'p-2'
        )}
      >
        {Array.from(Array(3).keys()).map(item => (
          <div key={item} className="bar bg-white w-full h-2px" />
        ))}
      </button>
    </div>
  );

  return (
    <div ref={ref} className={`navbar h-${topMenuHeight}px bg-brown text-white md:flex md:items-center md:justify-center md:h-60px`}>
      {renderMobileMenuBar()}
      {isMobile ? renderMobileMenu() : renderDesktopMenu()}
    </div>
  );
};

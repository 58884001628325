import React, { FC }  from 'react';
import classNames from 'classnames';
import './wrapper.css';

interface Props {
  className?: string;
}

export const Wrapper: FC<Props> = props => (
  <div className={classNames('container', 'ml-auto', 'mr-auto', 'pl-3', 'pr-3', 'lg:w-11/12', 'xl:w-9/12', 'm-auto', 'h-full', props.className)}>
    {props.children}
  </div>
);
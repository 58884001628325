import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getRandomInteger } from 'utils';
import { Reviews } from 'types';
import { Section } from '../section';

import './randomReview.css';

interface Props {
  reviews: Reviews;
}

export const RandomReview: FC<Props> = (props) => {
  const intl = useIntl();

  const filteredReviews = props.reviews.filter(review => review.language?.language === intl.locale);
  const randomReview = filteredReviews[getRandomInteger(0, filteredReviews.length - 1)];
  const removeTags = (str: string) => str.replace( /(<([^>]+)>)/ig, '');
  const parsedReview = `${removeTags(randomReview?.content || '').slice(0, 200).trim()}...`;

  return randomReview?.content ? (
    <Section label={<FormattedMessage id="reviews" defaultMessage="Atsiliepimai" />}>
      <div className="review italic">
        {parsedReview}
      </div>
    </Section>
  ) : null;
};

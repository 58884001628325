import React, { ComponentType } from 'react';
import { IntlProvider } from 'react-intl';
import { Language } from 'types';
import { getLocaleFromPath } from 'utils';

import messages_lt from '../translations/lt.json';
import messages_en from '../translations/en.json';
import messages_ru from '../translations/ru.json';

import { HomePageProps } from '../templates/homePage/types';
import { SinglePageProps } from '../templates/singlePage/types';
import { SingleRoomProps } from '../templates/singleRoom/types';

type HocProps = HomePageProps | SinglePageProps | SingleRoomProps;

const data: Record<Language, any> = {
  lt: messages_lt,
  en: messages_en,
  ru: messages_ru
};

export function withIntlProvider<T>(Component: ComponentType<T>) {
  return (hocProps: T & HocProps) => {
    const localeFromPath = getLocaleFromPath(hocProps.location.pathname);
    const language: Language = hocProps.pageContext.locale || localeFromPath;
    return (
      <IntlProvider locale={language} messages={data[language]}>
        <Component {...hocProps} />
      </IntlProvider>
    );
  };
}
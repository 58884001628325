import React, { FC } from 'react';
import { FormattedMessage, defineMessage, useIntl } from 'react-intl';
import { MenuItem, Reviews } from 'types';
import { defaultLanguage } from 'utils';
import '__generated__/gatsby-types';
import { RandomReview } from './randomReview';
import { Wrapper } from '../wrapper';
import { RoomsMenu } from './roomsMenu';
import { Contacts } from  './contacts';
import { ActionBar } from './actionBar';
import './footer.css';

interface Props {
  menuItems: MenuItem[];
  reviews: Reviews;
  openContactForm: VoidFunction;
}

const linkTitle = defineMessage({
  id: 'web_design_link_title',
  defaultMessage: 'Profesionalios interneto svetainės'
});

export const Footer: FC<Props> = props => {
  const intl = useIntl();

  const renderRulesLink = () => intl.locale === defaultLanguage ? <a className="text-brown underline" href="/vidaus-taisykles">
    <FormattedMessage
      id="house_rules"
      defaultMessage="Vidaus taisyklės"
    />
  </a> : null;

  return (
    <footer className="mt-20 color-white">
      <div className="footer-bg w-full transform rotate-180 h-50px" />
      <div className="footer-top pb-16">
        <Wrapper>
          <div className="md:flex">
            <RoomsMenu menuItems={props.menuItems} />
            <RandomReview reviews={props.reviews} />
            <Contacts />
          </div>
        </Wrapper>
      </div>
      <div className="footer-bottom pt-5px pb-5px bg-sand-100 text-dark">
        <Wrapper>
          <div className="sm:flex sm:justify-between">
            <div className="copyright">
              Copyright © Almuka, {new Date().getFullYear()}. {renderRulesLink()}
            </div>
            <div className="author">
              <FormattedMessage
                id="web_design"
                defaultMessage="Interneto svetainę sukūrė"
              />{': '}
              <a 
                target="_blank"
                className="text-brown-default underline"
                title={intl.formatMessage(linkTitle)}
                href="http://www.designus.lt/interneto-svetaines"
                rel="noreferrer"
              >Designus</a>
            </div>
          </div>
        </Wrapper>
      </div>
      <ActionBar openContactForm={props.openContactForm} />
    </footer>   
  );
};
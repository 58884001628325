import React, { FC, useState, useEffect, useCallback, useRef } from 'react';
import debounce from 'lodash/debounce';
import classNames from 'classnames';

import './ActionBar.css';

const offset = 200;

interface Props {
  openContactForm: VoidFunction;
}

export const ActionBar: FC<Props> = props => {
  const [isVisible, setIsVisible] = useState(false);
  const scrollPosition = useRef(0);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback(debounce(() => {
    const newScrollPosition = window.scrollY;
    if ((newScrollPosition - offset > scrollPosition.current) || (newScrollPosition + offset < scrollPosition.current)) {
      setIsVisible(false);
      scrollPosition.current = newScrollPosition;
    }
  }, 300), []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const scrollToTop = useCallback((duration: number) => {
    if (!document.scrollingElement || document.scrollingElement?.scrollTop === 0) return;
    const totalScrollDistance = document.scrollingElement?.scrollTop || 0;
    let scrollY = totalScrollDistance;
    let oldTimestamp: number | null = null;

    function step (newTimestamp: number) {
      if (document.scrollingElement && oldTimestamp !== null) {
        // if duration is 0 scrollY will be -Infinity
        scrollY -= totalScrollDistance * (newTimestamp - oldTimestamp) / duration;
        if (scrollY <= 0) {
          document.scrollingElement.scrollTop = 0;
          return;
        }
        document.scrollingElement.scrollTop = scrollY;
      }
      oldTimestamp = newTimestamp;
      window.requestAnimationFrame(step);
    }
    window.requestAnimationFrame(step);
  }, []);

  const actions: [JSX.Element, JSX.Element, JSX.Element, JSX.Element] = [
    <>
      <a aria-label="Make a call" href="tel:+37068351209">
        <i className="icon phone" />
      </a>
    </>,
    <>
      <div
        className="mb-5px"
        role="button"
        aria-label="Write an email"
        tabIndex={0}
        onClick={props.openContactForm}
      >
        <i className="icon email" />
      </div>
    </>,
    <>
      <a 
        aria-label="Open google maps"
        target="_blank"
        href="https://www.google.lt/maps/place/Almuka/@55.925323,21.0641133,17z/data=!3m1!4b1!4m5!3m4!1s0x46e52ecc0732f17f:0x3b658397ec054e96!8m2!3d55.92532!4d21.066302?hl=en" rel="noreferrer"
      >
        <i className="icon location" />
      </a>
    </>,
    <>
      <div
        role="button"
        tabIndex={0}
        aria-label="Scroll to top button"
        onClick={() => scrollToTop(300)}
      >
        <i className="icon arrow-top" />
      </div>
    </>
  ];

  return (
    <div className={classNames(
      'action-bar',
      'fixed',
      'bottom-0',
      'left-0',
      'z-10',
      'w-full',
      'select-none',
      'transform',
      'sm:hidden',
      {
        'translate-y-70px': !isVisible,
        'translate-y-0': isVisible
      }
    )}>
      <div 
        className={classNames(
          'toggle-button',
          'w-40',
          'h-40px',
          'ml-auto',
          'mr-auto',
          'relative',
          'flex',
          'items-center',
          'justify-center',
          'cursor-pointer',
          'overflow-hidden'
        )}
        role="button"
        tabIndex={0}
        aria-label="Toggle mobile bottom bar"
        onClick={toggleVisibility}
      >
        <div className={classNames(
          'trapezoid',
          'w-100px',
          'h-full',
          'bg-white',
          'rounded-tl-md',
          'rounded-tr-md',
          )}
        />
        <i className={classNames(
          'icon',
          'double-arrow-top',
          'absolute',
          'mt-5px',
          'text-gray-500',
          'text-3xl',
          'transform',
          'transition-transform',
          'duration-200',
          'origin-center',
          {
            'rotate-0': !isVisible,
            'rotate-180': isVisible
          }
        )}
        />
      </div>
      <div className="toggle-bar flex justify-between items-center h-70px border-sand-300 border-t bg-white bg-gradient-to-b from-white to-sand-300">
        {actions.map((action, index) => (
          <div key={index} className="action w-25% h-40px flex justify-center items-center">
            {action}
          </div>
        ))}
      </div>
    </div>
  );
};
import React, { FC } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Modal as ModalBase } from './modal';
import { ModalProps } from './types';

export const Modal: FC<ModalProps> = props => {
  return (
    <CSSTransition
      in={props.isOpen}
      classNames="modal-base"
      unmountOnExit
      timeout={0}
    >
      <ModalBase {...props}  />
    </CSSTransition>
  );
};


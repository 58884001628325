import { MenuItem, GraphqlMenuItem, Reviews } from 'types';

export const mapGraphqlMenuItemsToMenuItem = (items: GraphqlMenuItem[]): MenuItem[] => 
  items.map((item, index, arr): MenuItem => ({
    id: item.node.id,
    label: item.node.label,
    order: item.node.order,
    locale: item.node.menu?.node?.language?.language,
    path: item.node.path,
    title: item.node.title,
    parentId: item.node.parentId,
    items: mapGraphqlMenuItemsToMenuItem(arr.filter(childCandidate => childCandidate.node.parentId === item.node.id))
  }));

export const removeChildItems = (items: MenuItem[]) => items.filter(item => item.parentId === null);

export const getSortedReviews = (reviews: Reviews): Reviews => [...reviews]
  .sort((a, b) => {
    const aDate = a.review?.date ? new Date(a.review.date).getTime() : 0;
    const bDate = b.review?.date ? new Date(b.review.date).getTime() : 0;
    return bDate - aDate;
  });
export const getUrlEncodedString = (data: Record<string, unknown>) => {
  const urlencoded = new URLSearchParams();
  Object.entries(data).forEach(([key, value]) => {
    urlencoded.append(key, String(value));
  });

  return urlencoded;
};

export const callApi = async <T, K extends Record<string, any>>(
  url: string,
  method: 'GET' | 'POST',
  data: K,
  contentType: 'application/x-www-form-urlencoded; charset=UTF-8' | 'application/json'
): Promise<T | null> => {
  try {
    const requestOptions: RequestInit = {
      method,
      headers: {
        'Content-Type': contentType
      }
    };
    
    if (method === 'POST') {
      requestOptions.body = contentType === 'application/json' ? JSON.stringify(data) : getUrlEncodedString(data);
    }

    const response = await fetch(url, requestOptions);
    return response.json();
  } catch (err) {
    console.error('Unable to call API', err);
    return null;
  }
};

import React, { FC, lazy, Suspense } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'components/modal';
import { isSSR } from 'utils/methods';
import './contactUs.css';

const ContactForm = lazy(() => import('./contactForm/contactForm'));

interface Props {
  isModalOpen: boolean;
  toggleModal: VoidFunction;
}

export const ContactUs: FC<Props> = props => {
  return (
    <>
      <div className="hidden contact-button right-0 top-45% transform -rotate-90 z-10 md:block md:fixed">
        <div
          role="button"
          aria-label="Toggle contact form"
          tabIndex={0}
          onClick={props.toggleModal}
          className="bg-brown text-2xl text-white pt-9px pb-9px pl-7px pr-12px hover:bg-yellow-400 cursor-pointer select-none"
        >
          <i className="icon chat inline-block mr-5px transform rotate-90 text-xl" />
          <div className="pl-7px pr-7px text-center border-l border-opacity-20 inline-block ">
            <FormattedMessage 
              id="contact_us_button"
              defaultMessage="Rašykite :)"
            />
          </div>
        </div>
      </div>
      <Modal
        isOpen={props.isModalOpen}
        onClose={props.toggleModal}
        size="md"
        header={<FormattedMessage id="contact_us_header" defaultMessage="Rašykite mums" />}
      >
        {!isSSR ? (
          <Suspense fallback={<div>Loading....</div>}>
            <ContactForm />
          </Suspense>
        ) : null}
      </Modal>
    </>
  );
};

import React, { FC, useState, useEffect, MouseEvent } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { ModalProps } from './types';

import './modal.css';

export const Modal: FC<ModalProps> = props => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(props.isOpen);
  }, [props.isOpen]);

  const handleClose = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      props.onClose();
    }
  };

  const renderHeader = () => props.header ? (
    <div className="text-xl font-semibold border-b border-gray-300 flex justify-between">
      <div className="pt-15px pb-15px pl-15px pr-15px">
        {props.header}
      </div>
      <div className="flex items-center">
        <i
          role="button"
          tabIndex={0}
          onClick={props.onClose} 
          className="icon close text-dark text-4xl mr-10px cursor-pointer select-none"
        />
      </div>
    </div>
  ) : null;

  const renderContent = () => (
    <div className="pt-15px pb-15px pl-15px pr-15px">
      {props.children}
    </div>
  );

  const renderModal = () => (
    <CSSTransition
      in={showModal}
      classNames="modal"
      unmountOnExit
      timeout={300}
    >
      <div
        className={classNames(
          'modal',
          'shadow-dark-2xl',
          'rounded-md',
          'absolute',
          'bg-white',
          'z-40',
          'min-h-25%',
          'right-50%',
          'top-50%',
          'opacity-0',
          'transform',
          'max-h-screen',
          'overflow-y-auto',
          'translate-x-50%',
          '-translate-y-50%',
          {
            'w-90%': props.size === 'lg' || props.size === 'md',
            'lg:w-800px': props.size === 'lg',
            'lg:w-600px': props.size === 'md', 
            'opacity-100': showModal
          }
        )}
      >
        {renderHeader()}
        {renderContent()}
      </div>
    </CSSTransition>
  );

  const renderModalWrapper = () => (
    <div
      role="dialog"
      aria-label="Modal"
      onMouseDown={handleClose}
      className="modal-backdrop fixed bg-black top-0 left-0 right-0 bottom-0 bg-opacity-60 z-40"
    >
      {renderModal()}
    </div>
  );

  return createPortal(
    renderModalWrapper(),
    document.body
  );
};

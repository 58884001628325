import React, { FC } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Slider } from 'components/slider';
import { MediaItem, FeaturedImage } from 'types';
import { sortAscending } from 'utils';

export const HeaderSlider: FC = () => {
  const data = useStaticQuery<GatsbyTypes.HeaderSliderQuery>(graphql`
    query HeaderSlider {
      allWpMediaItem(filter: { mediaAttribute: { homeSlider: { eq: true }}}) {
        nodes {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                height: 330,
                quality: 60
                layout: FULL_WIDTH
                placeholder: BLURRED
                breakpoints: [800, 1200, 1800]
              )
            }
          }
          mediaAttribute {
            order
          }
        }
      }
    }
  `);

  const mapMediaItemToFeaturedImages = (mediaItems: MediaItem): FeaturedImage[] => mediaItems.nodes
    .map(mediaItem => ({
      alt: mediaItem.altText || '',
      data: mediaItem.localFile?.childImageSharp?.gatsbyImageData,
      order: mediaItem?.mediaAttribute?.order || 0
    }))
    .sort((a, b) => sortAscending(a.order, b.order));

  const sliderImages = mapMediaItemToFeaturedImages(data.allWpMediaItem);

  return (
    <Slider images={sliderImages} />
  );
};